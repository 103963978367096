import { DateField, NumberField, Show, SimpleShowLayout, TextField } from 'react-admin';

export const AccessPassShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField source="id" />
            <NumberField source="level" />
            <DateField source="created_at" />
            <DateField source="updated_at" />
        </SimpleShowLayout>
    </Show>
);
