import { FC } from 'react';
import { Edit, NumberInput, SimpleForm, TextInput } from 'react-admin';

export const PlaceEdit: FC = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" />
            <TextInput source="address" />
            <TextInput source="phone_number" />
            <NumberInput source="required_pass_level" />
            <NumberInput source="required_age_level" />
        </SimpleForm>
    </Edit>
);