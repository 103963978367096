import { FC } from 'react';
import { DateInput, Edit, NumberInput, SimpleForm, TextInput } from 'react-admin';

export const AccessPassEdit: FC = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" />
            <NumberInput source="level" />
            <DateInput source="created_at" />
            <DateInput source="updated_at" />
        </SimpleForm>
    </Edit>
);