import { FC } from 'react';
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  EmailField,
  ReferenceField,
  EditButton,
  NumberField
} from 'react-admin';
import {UserEdit} from "./UserEdit"

export const UserList: FC = (props) => (
  <List {...props}>
    <Datagrid rowClick="show">
    <ReferenceField source="id" reference="users" link="show" />
        <EmailField source="email" />
        <ReferenceField source="pass_id" reference="access_passes" />
        <TextField source="first_name" />
        <TextField source="last_name" />
 <TextField source="phone_number" />
        <TextField source="address" />
        <NumberField source="age" />
        <BooleanField source="is_admin" />
        <EditButton onClick={UserEdit}/>
    </Datagrid>
  </List>
);