import React, { FC } from 'react';
import { BooleanField, EmailField, NumberField, Show, SimpleShowLayout, TextField } from 'react-admin';

export const UserShow: FC = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <EmailField source="email" />
            <TextField source="password" />
            <TextField source="pass_id" />
            <TextField source="first_name" />
            <TextField source="last_name" />
            <TextField source="phone_number" />
            <TextField source="address" />
            <NumberField source="age" />
            <BooleanField source="is_admin" />
        </SimpleShowLayout>
    </Show>
);