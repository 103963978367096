import { fetchUtils, Admin, Resource } from "react-admin";
import dataProvider from "./dataProvider";
import authProvider from './authProvider';
import { UserCreate, UserEdit, UserList, UserShow } from "./Users";
import { PlaceCreate,PlaceList,PlaceShow,PlaceEdit } from "./Places";
import { AccessPassCreate, AccessPassEdit, AccessPassList, AccessPassShow } from "./AccessPasses";

const httpClient = (url: any, options: any) => {
  if (!options) {
    options = {};
  }
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const myDataProvider = dataProvider(process.env.REACT_APP_API_URL!,httpClient);

const App = () => (
  <Admin dataProvider={myDataProvider} authProvider={authProvider}>
    <Resource name="users" 
    list={UserList}
    edit={UserEdit}
    show={UserShow}
    create={UserCreate}
    />
    <Resource name="places" 
    list = {PlaceList}
    show={PlaceShow}
    edit={PlaceEdit}
    create={PlaceCreate}
    />
    <Resource name="access_passes" 
    list={AccessPassList} 
    show={AccessPassShow} 
    edit={AccessPassEdit}
    create={AccessPassCreate}
    />
  </Admin>
);

export default App;