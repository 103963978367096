import { FC } from 'react';
import { Datagrid, DateField, EditButton, List, NumberField, TextField } from 'react-admin';
import { AccessPassEdit } from './AccessPassEdit';

export const AccessPassList: FC = (props) => (
    <List {...props}>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <NumberField source="level" />
            <DateField source="created_at" />
            <DateField source="updated_at" />
            <EditButton onClick={AccessPassEdit}/>
        </Datagrid>
    </List>
);