import { FC } from 'react';
    import { Create, NumberInput, SimpleForm, TextInput } from 'react-admin';

export const PlaceCreate: FC = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="id" InputProps={{ disabled: true }} />
            <TextInput source="address" />
            <TextInput source="phone_number" />
            <NumberInput source="required_pass_level" />
            <NumberInput source="required_age_level" />
        </SimpleForm>
    </Create>
);