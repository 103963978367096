import { FC } from 'react';
import { Datagrid, List, NumberField, TextField, ReferenceField, EditButton } from 'react-admin';
import {PlaceEdit} from "./PlaceEdit";

export const PlaceList: FC = (props) => (
    <List {...props}>
        <Datagrid rowClick="show">
            <ReferenceField source="id" reference="places" link="show" />
            <TextField source="address" />
            <TextField source="phone_number" />
            <NumberField source="required_pass_level" />
            <NumberField source="required_age_level" />
            <EditButton onClick={PlaceEdit}/>
        </Datagrid>
    </List>
);