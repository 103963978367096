import { FC } from 'react';
import { DateInput, Create, NumberInput, SimpleForm, TextInput } from 'react-admin';

export const AccessPassCreate: FC = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="id" InputProps={{ disabled: true }}/>
            <NumberInput source="level" />
            <DateInput source="created_at" />
            <DateInput source="updated_at" />
        </SimpleForm>
    </Create>
);