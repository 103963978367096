import React, { FC } from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  PasswordInput,
  BooleanInput,
  NumberInput
} from 'react-admin';

export const UserEdit: FC = (props) => (
  <Edit {...props}>
    <SimpleForm>
            <TextInput source="id" InputProps={{ disabled: true }} />
            <TextInput source="email" />
            <PasswordInput source="password" />
            <TextInput source="pass_id" />
            <TextInput source="first_name" />
            <TextInput source="last_name" />
            <TextInput source="phone_number" />
            <TextInput source="address" />
            <NumberInput source="age" />
            <BooleanInput source="is_admin" />
        </SimpleForm>
  </Edit>
);