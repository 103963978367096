import { FC } from 'react';
import { NumberField, Show, SimpleShowLayout, TextField } from 'react-admin';

export const PlaceShow: FC = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="address" />
            <TextField source="phone_number" />
            <NumberField source="required_pass_level" />
            <NumberField source="required_age_level" />
        </SimpleShowLayout>
    </Show>
);